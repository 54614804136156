// React and Modules
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArrowLeftIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import logError from '../../../utils/errorUtil.js';

// Utils
import { useAuth } from '../../context/wms-context.js';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { camelCaseToTitleCase, mergePdfs, openDocumentInNewTab } from '../../utils/common-utils.js';
import { GuidedInterfaceContext } from './context/GuidedInterfaceContext.js';
// Other Pages
import {
    getTraceDocuments,
    sendChatterMessage,
    updateReleaseAndOrReleaseLineStatus,
    updateReleaseInspectionStatus,
} from './utils/guided-interface-functions';
import { releaseLineStatuses } from './utils/data.js';

export default function Trace({
    currentReleaseLine,
    documentsToPrint,
    setDocumentsToPrint,
    setShowNotification,
    releaseName,
    returnToReleaseLines,
    releaseId,
}) {
    const { currentUser } = useAuth();
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [loadingPrintSelectedDocuments, setLoadingPrintSelectedDocuments] = useState(false);
    const [reviewReason, setReviewReason] = useState('');
    const { partInspectionSeconds, partInspectionTimeDisplay, startTimer, stopTimer } = useContext(GuidedInterfaceContext);
    const [loadingRejection, setLoadingRejection] = useState(false);
    const [reviewDropdownOpen, setReviewDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (!documentsToPrint.documentsFetched && !documentsToPrint.fetchingDocuments) {
            setDocumentsToPrint({ ...documentsToPrint, fetchingDocuments: true });
            fetchDocuments();
        }
    });
    useEffect(() => {
        const timer = startTimer();

        return () => stopTimer(timer);
    }, []);

    async function fetchDocuments() {
        try {
            setLoadingDocuments(true);

            const { data } = await getTraceDocuments(currentUser, currentReleaseLine);

            setDocumentsToPrint({
                documentsFetched: true,
                fetchingDocuments: false,
                ownerCode: data.ownerCode,
                partRecord: data.partRecord,
                traceTag: data.traceTag,
                oneClickBinder: data.oneClickBinder,
            });
        } catch (error) {
            logError('There was an error fetching the documents for the Trace Tab.', error);
            setShowNotification({
                shown: true,
                status: 'error',
                title: 'Error Fetching Documents',
                message:
                    'There was an error fetching the documents for the Trace Tab. Please try again, and if the issue persists, contact a manager.',
            });
            setDocumentsToPrint({
                ...documentsToPrint,
                fetchingDocuments: false,
                documentsFetched: true,
            });
        } finally {
            setLoadingDocuments(false);
        }
    }

    const togglePrintDocument = (document) => {
        let currentDocData = documentsToPrint[document];

        currentDocData.printDocument = !currentDocData.printDocument;

        setDocumentsToPrint({
            ...documentsToPrint,
            [document]: currentDocData,
        });
    };

    const printSelectedDocuments = async () => {
        setLoadingPrintSelectedDocuments(true);

        try {
            const documentPdfs = Object.keys(documentsToPrint)
                .map((documentKey) => {
                    const currentDocument = documentsToPrint[documentKey];
                    if (documentKey === 'documentsFetched' || !currentDocument.printDocument) return null;

                    return currentDocument.pdf;
                })
                .filter(Boolean);

            if (!documentPdfs.length) {
                setShowNotification({
                    shown: true,
                    status: 'error',
                    title: 'No Documents Selected',
                    message: 'Please select at least one document to print.',
                });
                return;
            }

            const response = await mergePdfs(documentPdfs);

            // Open the pdf in a new tab
            openDocumentInNewTab(response);

            await updateReleaseAndOrReleaseLineStatus(
                currentUser,
                releaseId,
                currentReleaseLine.releaseLineId,
                releaseLineStatuses.IN_QC
            );

            const { statusResponse, timeResponse } = await updateReleaseInspectionStatus(
                'complete',
                'trace',
                currentReleaseLine.releaseLineName,
                releaseName,
                partInspectionSeconds,
                currentUser
            );

            if (statusResponse === 'success' && timeResponse === 'success') {
                setShowNotification({
                    shown: true,
                    status: 'success',
                    title: 'Trace Documents Printed Successfully',
                });

                returnToReleaseLines();
            } else {
                setShowNotification({
                    shown: true,
                    status: 'error',
                    title: 'Failed to Update the Status of this Release Line. Please try again.',
                });
            }
        } catch (error) {
            logError('There was an error printing the selected documents.', error);
            setShowNotification({
                shown: true,
                status: 'error',
                title: 'Error Printing Documents',
                message:
                    'There was an error printing the selected documents. Please try again, and if the issue persists, contact a manager.',
            });
        } finally {
            setLoadingPrintSelectedDocuments(false);
        }
    };

    const handleReviewSubmit = async () => {
        setLoadingRejection(true);

        try {
            const chatterResponse = await sendChatterMessage(currentUser, currentReleaseLine, reviewReason);

            await updateReleaseAndOrReleaseLineStatus(
                currentUser,
                releaseId,
                currentReleaseLine.releaseLineId,
                releaseLineStatuses.PENDING_SALES
            );

            const { statusResponse, timeResponse } = await updateReleaseInspectionStatus(
                'rejected',
                'trace',
                currentReleaseLine.releaseLineName,
                releaseName,
                partInspectionSeconds,
                currentUser
            );
            if (chatterResponse.success && statusResponse === 'success' && timeResponse === 'success') {
                setShowNotification({
                    shown: true,
                    status: 'success',
                    title: 'Submission Successful',
                });
            } else {
                setShowNotification({
                    shown: true,
                    status: 'error',
                    title: 'Failed to Submit Request',
                    message: 'There was an error submitting the request. Please try again.',
                });
            }
        } finally {
            setLoadingRejection(false);
            returnToReleaseLines();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (reviewDropdownOpen && !dropdownRef?.current?.contains(event.target)) {
                setReviewDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef, reviewDropdownOpen]);

    return (
        <section className='px-8 pb-20'>
            <header className='flex h-10 mt-10'>
                <button
                    className='z-10 flex items-center justify-center w-24 h-10 px-3 my-auto text-lg text-white rounded-md bg-blue-primary hover:bg-blue-secondary'
                    onClick={returnToReleaseLines}
                >
                    <ArrowLeftIcon className='w-6 h-6 mr-2' />
                    <span>Back</span>
                </button>

                <div className='flex-grow text-center -ml-36'>
                    <h1 className='text-4xl font-bold'>
                        Trace for{' '}
                        <a
                            className='text-blue-900 underline hover:text-blue-400'
                            href={currentReleaseLine.invLineLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {currentReleaseLine?.invLineName}
                        </a>
                    </h1>
                    <p className='mt-2 text-lg font-bold'>Select Documents to Print</p>
                </div>
            </header>
            {loadingDocuments ? (
                <div className='flex flex-col items-center justify-center h-screen -mt-32'>
                    <LoadingSpinner className='w-44' spinnerSize={'32'} />
                    <p className='mt-6'>Loading Documents....</p>
                </div>
            ) : (
                <article className='mx-16 mb-20 overflow-auto mt-28'>
                    <div className='grid grid-cols-4 gap-4 min-h-[36vh]'>
                        {documentsToPrint.documentsFetched &&
                            Object.keys(documentsToPrint)
                                ?.map((documentKey) => {
                                    let currentDocument = documentsToPrint[documentKey];
                                    if (documentKey === 'documentsFetched' || documentKey === 'fetchingDocuments')
                                        return null;

                                    const titleCaseDocument = camelCaseToTitleCase(documentKey);
                                    if (!currentDocument?.pdf) {
                                        return (
                                            <div className='px-2 pt-1 overflow-hidden text-xl font-semibold border rounded-lg'>
                                                <div className='flex justify-between'>{titleCaseDocument}</div>
                                                <div className='flex items-center justify-center w-full h-full'>
                                                    {currentDocument?.text}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className={`cursor-pointer font-semibold text-xl pt-1 px-2 border rounded-lg overflow-hidden
                                        ${currentDocument?.printDocument && 'bg-blue-200 border-blue-400'}`}
                                                onClick={() => togglePrintDocument(documentKey)}
                                            >
                                                <div className='flex justify-between'>
                                                    {titleCaseDocument}
                                                    <CheckCircleIcon
                                                        className={`h-8 w-8 rounded-full ${
                                                            currentDocument?.printDocument
                                                                ? 'text-green-400'
                                                                : 'text-gray-400'
                                                        }`}
                                                    />
                                                </div>
                                                <div className='flex items-center justify-center w-auto h-full pt-3'>
                                                    <img
                                                        src={`data:image/jpeg;base64,${currentDocument?.image}`}
                                                        alt='Owner Code'
                                                        className='object-contain max-w-full max-h-full mb-6 bg-white border border-black rounded-md'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openDocumentInNewTab(currentDocument?.pdf);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                                .filter(Boolean)}
                    </div>
                    <div className='flex justify-center'>
                        <button
                            disabled={loadingPrintSelectedDocuments}
                            className='w-full px-3 py-2 mx-5 mt-8 text-2xl font-semibold text-white bg-green-500 border rounded-md'
                            onClick={printSelectedDocuments}
                        >
                            {loadingPrintSelectedDocuments ? (
                                <div className='flex items-center justify-center'>
                                    <LoadingSpinner className='w-44' spinnerSize={'8'} color={'text-white'} />
                                </div>
                            ) : (
                                'Print Selected Documents'
                            )}
                        </button>
                    </div>
                    <div className='mt-10 bg-gray-100 rounded-lg'>
                        <p className='pt-8 pl-3 text-2xl font-bold'>Request Review</p>
                        <div className='p-6'>
                            <button
                                type='button'
                                className='filter-button inline-flex justify-center px-4 mb-3 py-2 bg-white border border-gray-700 rounded focus:outline-none focus:ring focus:ring-blue-200'
                                onClick={() => setReviewDropdownOpen(!reviewDropdownOpen)}
                            >
                                Update Request Text
                                {reviewDropdownOpen ? (
                                    <ChevronUpIcon className='w-5 h-5 ml-2' />
                                ) : (
                                    <ChevronDownIcon className='w-5 h-5 ml-2' />
                                )}
                            </button>
                            <div ref={dropdownRef}>
                                <RejectReviewDropdown
                                    reviewDropdownOpen={reviewDropdownOpen}
                                    reviewReason={reviewReason}
                                    setReviewReason={setReviewReason}
                                    setReviewDropdownOpen={setReviewDropdownOpen}
                                    releaseLineName={currentReleaseLine.releaseLineName}
                                />
                            </div>

                            <button
                                disabled={reviewReason?.length < 1 || loadingRejection}
                                className='w-full py-2 text-2xl font-semibold text-white bg-red-600 border rounded-md cursor-pointer'
                                onClick={handleReviewSubmit}
                            >
                                {loadingRejection ? (
                                    <div className='flex items-center justify-center'>
                                        <LoadingSpinner className='w-44' spinnerSize={'8'} color={'text-white'} />
                                    </div>
                                ) : (
                                    'Submit Request'
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            )}
        </section>
    );
}

function RejectReviewDropdown({ reviewDropdownOpen, setReviewDropdownOpen, setReviewReason, releaseLineName }) {
    const dropdownOptions = [
        { value: 'Insufficient Qty on documentation', checked: false },
        { value: 'Trace not readable', checked: false },
        { value: 'No 8130 tag', checked: false },
        { value: 'Needs to be added to binder', checked: false },
        { value: 'Wrong condition code on documentation', checked: false },
        { value: 'Other', checked: false },
    ];
    const [checkedReasons, setCheckedReasons] = useState(dropdownOptions);
    const [userInput, setUserInput] = useState('');

    const handleCheckedUpdate = (dropdownOption, isChecked) => {
        let updatedReasons;

        if (dropdownOption.value === 'Other' && !dropdownOption.checked) {
            setReviewDropdownOpen(false);
        }

        updatedReasons = checkedReasons.map((checkedReason) => {
            if (checkedReason.value === dropdownOption.value) {
                checkedReason.checked = isChecked;
            }

            return checkedReason;
        });

        setCheckedReasons(updatedReasons);
    };

    useEffect(() => {
        let reviewRequestMessage = '';

        if (checkedReasons.some((req) => req.checked)) {
            reviewRequestMessage = `Release Line ${releaseLineName} was rejected due to the following reason(s): \n`;
            checkedReasons.forEach((req) => {
                if (req.checked && req.value === 'Other') {
                    reviewRequestMessage += `- ${userInput}`;
                }
                if (req.checked && req.value !== 'Other') {
                    reviewRequestMessage += `- ${req.value} \n`;
                }
            });
        }

        setReviewReason(reviewRequestMessage);
    }, [checkedReasons, userInput]);

    return (
        <section className='flex flex-col mb-2'>
            {reviewDropdownOpen ? (
                <div className='absolute flex flex-col z-20 mt-2 bg-white border border-gray-300 rounded shadow-lg p-2 w-fit gap-y-1'>
                    {checkedReasons.map((dropdownOption, index) => (
                        <label key={index} className='flex items-center w-full cursor-pointer'>
                            <input
                                type='checkbox'
                                className='form-checkbox h-4 w-4'
                                checked={checkedReasons.find(
                                    (reason) => reason.value === dropdownOption.value && reason.checked
                                )}
                                onChange={(e) => handleCheckedUpdate(dropdownOption, e.target.checked)}
                            />
                            <span className='ml-2 text-sm'>{dropdownOption.value}</span>
                        </label>
                    ))}
                </div>
            ) : null}
            {checkedReasons.some((req) => req.checked && req.value === 'Other') ? (
                <input
                    className='border rounded-md border-black px-3 py-2'
                    placeholder='Enter custom review message here'
                    onChange={(e) => setUserInput(e.target.value)}
                />
            ) : null}
        </section>
    );
}