import React, { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { defaultDarValue, documentKeys, documentNames, documentReferences, overrideDefaults } from './utils/data';
import { FormContext } from '../context/FormContextWrapper';
import { useAuth } from '../../../context/wms-context';
import {
    addOverridesToFormData,
    getDataForDocuments,
    printGeneratedDocuments,
    submitUpdatedHeaderData,
} from './utils/functions';
import { GuidedInterfaceContext } from '../context/GuidedInterfaceContext';
import Document8130Form from './formOverrides/Document8130Form';
import ASADocumentForm from './formOverrides/ASADocumentForm';
import BoeingDocumentForm from './formOverrides/BoeingDocumentForm';
import PackingSlipForm from './formOverrides/PackingSlipForm';
import { updateReleaseInspectionStatus } from '../utils/guided-interface-functions';

export default function Forms({ currentReleaseLine, releaseData, releaseName, returnToReleaseLines }) {
    const { currentUser } = useAuth();
    const {
        documentDefaults,
        updateDocumentDefaults,
        packingSheetLastPrinted,
        documentInfo,
        setDocumentInfo,
        selectedDocuments,
        setSelectedDocuments,
    } = useContext(FormContext);
    const { partInspectionSeconds, partInspectionTimeDisplay, setShowNotification, startTimer, stopTimer } =
        useContext(GuidedInterfaceContext);

    const [formOverrides, setFormOverrides] = useState({ ...documentDefaults });
    const [currentForm, setCurrentForm] = useState(null);
    const [loading, setLoading] = useState(true);
    const [acceptButtonLoading, setAcceptButtonLoading] = useState(false);
    const [printButtonLoading, setPrintButtonLoading] = useState(false);
    const [persistedDocumentInfo, setPersistedDocumentInfo] = useState({});
    const [requiredDocuments, setRequiredDocuments] = useState([
        documentReferences.DOCUMENT_ASA,
        documentReferences.PACKING_SLIP_UNPRICED,
    ]);
    const [nonRequiredDocuments, setNonRequiredDocuments] = useState([]);
    const [darPrinted, setDarPrinted] = useState();

    const isInternational = releaseData.isInternational;
    const isWillCall = releaseData.isWillCall;
    const isBoeingDropship = releaseData.isBoeingDropship;
    const isDAR = releaseData.darRequired;
    const [conditionCodes, setConditionCodes] = useState([]);

    let releaseId = releaseData.id;

    const getFormDataAndSetOverrides = async () => {
        try {
            setLoading(true);
            const response =
                documentInfo ??
                (await getDataForDocuments(releaseData.name, currentReleaseLine.releaseLineName, currentUser));

            let tempSelectedDocuments = [];
            const documentNames = Object.keys(documentKeys);
            response.previouslyPrintedDocuments?.forEach((documentType) => {
                if (documentType === 'packingSheet' && !tempSelectedDocuments.includes('packingSheetPriced')) {
                    const packSheetPriced = documentNames.find((name) => documentKeys[name] === 'packingSheetPriced');
                    const packSheetUnpriced = documentNames.find((name) => documentKeys[name] === 'packingSheetUnpriced');
                    tempSelectedDocuments.push(packSheetPriced);
                    tempSelectedDocuments.push(packSheetUnpriced);
                }

                if (documentType !== 'packingSheet') {
                    const documentKey = documentNames.find((name) => documentKeys[name] === documentType);
                    tempSelectedDocuments.push(documentKey);
                }
            });

            handleRequiredDocuments();
            setSelectedDocuments(tempSelectedDocuments);
            setDocumentInfo(response);
            setConditionCodes(response.conditionCodes);
            setPersistedDocumentInfo(response.documentData);
            combineAllFormDataWithOverrides(response.documentData);
        } catch (error) {
            setShowNotification({
                error: true,
                shown: true,
                title: 'Error Getting Data For Document(s)',
                message:
                    'There was an error generating data for documents. Please try again and if this issue persists contact your manager.',
            });
        }
        setLoading(false);
    };

    const handleRequiredDocuments = () => {
        const { DOCUMENT_8130, DOCUMENT_BOEING, PACKING_SLIP_PRICED } = documentReferences;
        const dynamicDocuments = [DOCUMENT_BOEING, DOCUMENT_8130, PACKING_SLIP_PRICED];

        let required = [];
        let nonRequired = [];
        for (const docName of dynamicDocuments) {
            switch (docName) {
                case PACKING_SLIP_PRICED:
                    {
                        if (isInternational && !isBoeingDropship) {
                            required.push(PACKING_SLIP_PRICED);
                            continue;
                        }
                        nonRequired.push(PACKING_SLIP_PRICED);
                    }
                    break;
                case DOCUMENT_BOEING:
                    {
                        if (isInternational && !isBoeingDropship) {
                            required.push(DOCUMENT_BOEING);
                            continue;
                        }
                        nonRequired.push(DOCUMENT_BOEING);
                    }
                    break;
                case DOCUMENT_8130: {
                    if (isDAR) {
                        required.push(DOCUMENT_8130);
                        continue;
                    }
                    nonRequired.push(DOCUMENT_8130);
                }
            }
        }

        setRequiredDocuments((docs) => {
            return [...docs, ...required];
        });
        setNonRequiredDocuments((docs) => {
            return [...docs, ...nonRequired];
        });
    };

    const combineAllFormDataWithOverrides = (documentData) => {
        const updatedFormOverrides = _.clone(formOverrides);

        // Go through each document
        for (const documentName in formOverrides) {
            const totalDocumentData = _.clone(documentData[documentName]);
            const updatedDocument = _.clone(
                totalDocumentData.find((data) => data.inventoryName === currentReleaseLine.invLineName) ?? {}
            );
            const overrideDocument = _.clone(formOverrides[documentName]);
            const persistedDocumentOverrides = _.clone(overrideDefaults[documentName]);

            const isPackSheet =
                documentName === documentKeys.PACKING_SLIP_PRICED || documentName === documentKeys.PACKING_SLIP_UNPRICED;
            if (documentName === packingSheetLastPrinted) continue;
            if (isPackSheet && packingSheetLastPrinted) {
                const allPackingData = _.clone(documentData[packingSheetLastPrinted]);
                const persistedPackingDocument = _.clone(
                    allPackingData.find((data) => data.inventoryName === currentReleaseLine.invLineName) ?? {}
                );
                // Go through each key to check for updates
                for (const override in updatedDocument) {
                    if (!(override in persistedDocumentOverrides)) continue;
                    if (persistedPackingDocument[override] !== overrideDocument[override]) {
                        overrideDocument[override] = persistedPackingDocument[override];
                    }
                }
                continue;
            }

            // Go through each key to check for updates
            for (const override in updatedDocument) {
                if (updatedDocument[override] !== overrideDocument[override]) {
                    overrideDocument[override] = updatedDocument[override];
                }
            }

            updatedFormOverrides[documentName] = overrideDocument;
        }

        setFormOverrides(updatedFormOverrides);
    };

    const dynamicallyParseFormData = (event, formName) => {
        const inputType = event.target.type;
        const keyName = event.target.name;
        const value = inputType === 'checkbox' ? event.target.checked : event.target.value;

        const overrides = { ...formOverrides };

        const isDarUpdate = keyName === 'darDisplayName' && formName === documentKeys.DOCUMENT_8130;

        if (isDarUpdate) {
            const darOptions = overrides.form8130.DAR;
            const selectedOption = darOptions?.find((dar) => dar.displayName === value) ?? defaultDarValue;
            const { authorizationName, authorizationNumber, displayName } = selectedOption;

            overrides.form8130.DAR = overrides.form8130.DAR.map((dar) => {
                if (dar.displayName === displayName) {
                    return { ...dar, priority: true };
                }
                return { ...dar, priority: false };
            });
            overrides.form8130.authorizationName = authorizationName;
            overrides.form8130.authorizationNumber = authorizationNumber;
            overrides.form8130.displayName = displayName;
            return;
        }

        // Link both packing sheets together
        const isPackSheet = formName === documentKeys.PACKING_SLIP_PRICED || formName === documentKeys.PACKING_SLIP_UNPRICED;
        if (isPackSheet) {
            overrides[documentKeys.PACKING_SLIP_PRICED][keyName] = value;
            overrides[documentKeys.PACKING_SLIP_UNPRICED][keyName] = value;
            setFormOverrides(() => {
                return overrides;
            });
            return;
        }

        if (formName === 'form8130' && (keyName === 'showLeft' || keyName === 'showRight')) {
            if (keyName === 'showLeft' && overrides.form8130.showRight === true) {
                overrides.form8130.showLeft = true;
                overrides.form8130.showRight = false;
            } else if (keyName === 'showRight' && overrides.form8130.showLeft === true) {
                overrides.form8130.showLeft = false;
                overrides.form8130.showRight = true;
            }
            setFormOverrides(() => {
                return overrides;
            });
            return;
        }

        overrides[formName][keyName] = value;
        setFormOverrides(overrides);
    };

    const handleDocumentSelection = (documentKeyValue) => {
        setCurrentForm(documentKeyValue);
    };

    const formatDocumentsToPrint = () => {
        const documentsToPrint = {};

        if (currentForm in documentKeys) {
            const formKey = documentKeys[currentForm];

            // This was added to handle a feature regarding ASA or Boeing documents but there were merge conflicts
            // and the feature was not implemented, and not found out until later. This is a note to remove this at a later date if not resolved.
            // const asaOrBoeing = formKey === documentKeys.DOCUMENT_ASA || formKey === documentKeys.DOCUMENT_BOEING;
            // const correctDocumentInfo = asaOrBoeing ? documentInfo.documentData[formKey] : documentInfo[formKey];
            const correctDocumentInfo = documentInfo.documentData[formKey];

            const matchingDocuments = correctDocumentInfo.filter((data) => {
                if (formKey.includes('packingSheet')) return data; // Include all lines for packing sheets
                if (data.inventoryName === currentReleaseLine.invLineName) return data;
            });
            documentsToPrint[formKey] = matchingDocuments;
        }

        return documentsToPrint;
    };

    // Compare previous pack sheet to the pack sheet submitted
    const getUpdatedPackingSheetData = () => {
        const updatedPackSheet = formOverrides?.packingSheetPriced || formOverrides?.packingSheetUnpriced;

        if (!updatedPackSheet) return;

        const originalPackSheet = persistedDocumentInfo?.packingSheetPriced?.find(
            (data) => data.inventoryName === currentReleaseLine.invLineName
        );

        if (!originalPackSheet || !updatedPackSheet) return;

        const updatedPackSheetData = {
            awb: updatedPackSheet?.awb?.toString(),
            externalComments: updatedPackSheet?.externalComments?.toString(),
            shippedMethod: updatedPackSheet?.shippedMethod?.toString(),
            totalPieces: updatedPackSheet?.totalPieces?.toString(),
            totalWeight: updatedPackSheet?.totalWeight?.toString(),
        };
        const oldPackSheetData = {
            awb: originalPackSheet?.awb?.toString(),
            externalComments: originalPackSheet?.externalComments?.toString(),
            shippedMethod: originalPackSheet?.shippedMethod?.toString(),
            totalPieces: originalPackSheet?.totalPieces?.toString(),
            totalWeight: originalPackSheet?.totalWeight?.toString(),
        };

        const isPackingSheetTheSame = JSON.stringify(oldPackSheetData) === JSON.stringify(updatedPackSheetData);
        if (!isPackingSheetTheSame) return { ...updatedPackSheetData, releaseId: releaseId };
    };

    const handleFormPrint = async () => {
        setPrintButtonLoading(true);

        try {
            const updatedPackingSheetData = getUpdatedPackingSheetData();
            if (updatedPackingSheetData) {
                await submitUpdatedHeaderData(currentUser, updatedPackingSheetData);
            }

            const formattedDocuments = formatDocumentsToPrint();
            const updatedDocumentData = addOverridesToFormData(formOverrides, formattedDocuments);

            await printGeneratedDocuments(updatedDocumentData, currentUser, isInternational, isWillCall);
            updateDocumentDefaults(updatedDocumentData, currentReleaseLine.invLineName);
            setSelectedDocuments([...selectedDocuments, currentForm]);
            setCurrentForm(null);
        } catch (error) {
            setShowNotification({
                error: true,
                shown: true,
                title: 'Error Printing Document(s)',
                message:
                    'There was an error printing documents. Please try again and if this issue persists contact your manager.',
            });
        } finally {
            setPrintButtonLoading(false);
        }
    };

    const handleSaveInspectionStatus = async () => {
        setAcceptButtonLoading(true);
        const { statusResponse, timeResponse } = await updateReleaseInspectionStatus(
            'complete',
            'forms',
            currentReleaseLine.releaseLineName,
            releaseName,
            partInspectionSeconds,
            currentUser
        );
        if (statusResponse === 'success' && timeResponse === 'success') {
            setShowNotification({
                shown: true,
                status: 'success',
                title: 'Status Saved Successfully',
                message: 'Forms status was saved successfully.',
            });
        } else {
            setShowNotification({
                shown: true,
                status: 'error',
                title: 'Failed to Update Status',
                message: 'There was an error saving the status of the Forms page. Please try again.',
            });
        }

        returnToReleaseLines();
        setAcceptButtonLoading(false);
    };

    const handleBackButton = () => {
        returnToReleaseLines();
        setCurrentForm(null);
    };

    useEffect(() => {
        getFormDataAndSetOverrides();
    }, []);

    useEffect(() => {
        if (selectedDocuments.includes(documentReferences.DOCUMENT_8130)) {
            setDarPrinted(true);
        }
    }, [selectedDocuments]);

    useEffect(() => {
        if (isDAR) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [releaseData]);

    function handleBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = 'The release is a DAR. Do you really want to leave?';
    }

    return (
        <section className='flex flex-col items-center justify-start h-full px-8 pt-10 pb-20 gap-y-8'>
            <header className='flex flex-col items-center justify-start w-full'>
                {(!isDAR || (isDAR && darPrinted)) && (
                    <button
                        className='absolute z-10 flex items-center justify-center w-24 h-10 px-3 my-auto text-lg text-white rounded-md left-8 bg-blue-primary hover:bg-blue-secondary'
                        onClick={handleBackButton}
                    >
                        <ArrowLeftIcon className='w-6 h-6 mr-2' />
                        <span>Back</span>
                    </button>
                )}
                <article className='flex flex-col items-center'>
                    <h1 className='text-4xl font-bold'>
                        Forms for{' '}
                        <a
                            className='text-blue-900 underline hover:text-blue-400'
                            href={currentReleaseLine.invLineLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {currentReleaseLine?.invLineName}
                        </a>
                    </h1>
                    <p className='mt-2 text-lg font-bold'>Select Forms to Print</p>
                </article>
            </header>
            {loading ? (
                <section className='absolute top-0 left-0 flex items-center justify-center w-screen h-screen'>
                    <LoadingSpinner className={'h-fit'} />
                </section>
            ) : (
                <section className='w-full flex flex-col items-center justify-center gap-y-8 max-w-[1280px]'>
                    <section className='w-full flex flex-col gap-y-8 '>
                        <article className='flex flex-col w-full gap-2'>
                            <h1 className='text-lg font-bold'>Required</h1>
                            <article className='flex flex-row w-full p-3 bg-gray-100 border border-gray-300 rounded-md'>
                                <div className='w-1/2 '>
                                    <h1 className='w-full font-semibold text-left'>Form</h1>
                                </div>
                                <div className='w-1/2 '>
                                    <h1 className='w-full font-semibold text-center'>Printed</h1>
                                </div>
                            </article>
                            {requiredDocuments?.length &&
                                requiredDocuments?.map((name, index) => {
                                    return (
                                        <FormRowItem
                                            key={index}
                                            formName={name}
                                            handleDocumentSelection={handleDocumentSelection}
                                            selectedDocuments={selectedDocuments}
                                            dynamicallyParseFormData={dynamicallyParseFormData}
                                            currentForm={currentForm}
                                            formOverrides={formOverrides}
                                            conditionCodes={conditionCodes}
                                        />
                                    );
                                })}
                        </article>
                        <article className='flex flex-col w-full gap-2'>
                            <h1 className='text-lg font-bold'>Not Required</h1>
                            <article className='flex flex-row w-full p-3 bg-gray-100 border border-gray-300 rounded-md'>
                                <div className='w-1/2 '>
                                    <h1 className='w-full font-semibold text-left'>Form</h1>
                                </div>
                                <div className='w-1/2 '>
                                    <h1 className='w-full font-semibold text-center'>Printed</h1>
                                </div>
                            </article>
                            {nonRequiredDocuments?.length &&
                                nonRequiredDocuments?.map((name, index) => {
                                    return (
                                        <FormRowItem
                                            key={index}
                                            formName={name}
                                            handleDocumentSelection={handleDocumentSelection}
                                            selectedDocuments={selectedDocuments}
                                            dynamicallyParseFormData={dynamicallyParseFormData}
                                            currentForm={currentForm}
                                            formOverrides={formOverrides}
                                            conditionCodes={conditionCodes}
                                        />
                                    );
                                })}
                        </article>
                    </section>
                    <section className='flex flex-col w-full gap-y-8'>
                        {currentForm ? (
                            <button
                                className='w-full px-3 py-2 text-2xl text-white bg-green-600 border rounded-md hover:bg-green-500 disabled:pointer-events-none'
                                onClick={handleFormPrint}
                                disabled={!currentForm || printButtonLoading}
                            >
                                {printButtonLoading ? (
                                    <div className='flex items-center justify-center'>
                                        <LoadingSpinner className='w-44' spinnerSize={'8'} color={'text-white'} />
                                    </div>
                                ) : (
                                    'Print Form'
                                )}
                            </button>
                        ) : (
                            <button
                                className='w-full px-3 py-2 text-2xl text-white bg-green-600 rounded-md my-2border hover:bg-green-500 disabled:pointer-events-none'
                                onClick={handleSaveInspectionStatus}
                                disabled={!selectedDocuments.length || acceptButtonLoading || (isDAR && !darPrinted)}
                            >
                                {acceptButtonLoading ? (
                                    <div className='flex items-center justify-center'>
                                        <LoadingSpinner className='w-44' spinnerSize={'8'} color={'text-white'} />
                                    </div>
                                ) : (
                                    'Accept Forms'
                                )}
                            </button>
                        )}
                    </section>
                </section>
            )}
        </section>
    );
}

function FormRowItem({
    formName,
    handleDocumentSelection,
    selectedDocuments,
    dynamicallyParseFormData,
    currentForm,
    formOverrides,
    conditionCodes,
}) {
    const handleSelection = () => {
        if (currentForm === formName) {
            handleDocumentSelection(null);
            return;
        }
        handleDocumentSelection(formName);
    };

    return (
        <section>
            <section
                className={
                    'flex flex-col w-full border-gray-300 overflow-hidden h cursor-pointer duration-75 transition-colors bg-gray-100 hover:bg-gray-200 ' +
                    (currentForm === formName ? ' rounded-t-md border-x border-t ' : ' border rounded-md')
                }
                onClick={handleSelection}
            >
                <section
                    className={'flex flex-row w-full gap-2 border-gray-300 p-3 ' + (currentForm === formName && 'border-b')}
                >
                    <article className='flex flex-row w-1/2 gap-3 rounded-md '>
                        <ChevronRightIcon
                            className={'h-4 w-4 duration-75 mr-4 ' + (currentForm === formName && 'rotate-90')}
                        />
                        <h1 className='w-full font-semibold text-left'>{documentNames[formName]}</h1>
                    </article>
                    <article className='flex items-center justify-center w-1/2 rounded-md '>
                        <input type='checkbox' readOnly={true} checked={selectedDocuments.includes(formName)} />
                    </article>
                </section>
            </section>
            <form
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    dynamicallyParseFormData(e, documentKeys[formName]);
                }}
                className={
                    'w-full flex-col justify-between gap-4 px-4 py-5 duration-75 bg-gray-50 rounded-b-md border-x border-b border-gray-300 ' +
                    (currentForm === formName ? 'flex' : 'hidden')
                }
            >
                <FormInputs
                    formName={formName}
                    currentForm={currentForm}
                    formOverrides={formOverrides}
                    conditionCodes={conditionCodes}
                />
            </form>
        </section>
    );
}

function FormInputs({ formName, currentForm, formOverrides, conditionCodes }) {
    if (formName === documentReferences.DOCUMENT_8130) {
        return <Document8130Form documentDefaults={formOverrides} />;
    }

    if (formName === documentReferences.DOCUMENT_ASA) {
        return <ASADocumentForm documentDefaults={formOverrides} conditionCodes={conditionCodes} />;
    }

    if (formName === documentReferences.DOCUMENT_BOEING) {
        return <BoeingDocumentForm documentDefaults={formOverrides} conditionCodes={conditionCodes} />;
    }

    if (formName === documentReferences.PACKING_SLIP_PRICED) {
        return (
            <PackingSlipForm
                formName={documentKeys.PACKING_SLIP_PRICED}
                documentDefaults={currentForm === documentReferences.PACKING_SLIP_PRICED ? formOverrides : {}}
            />
        );
    }

    if (formName === documentReferences.PACKING_SLIP_UNPRICED) {
        return (
            <PackingSlipForm
                formName={documentKeys.PACKING_SLIP_UNPRICED}
                documentDefaults={currentForm === documentReferences.PACKING_SLIP_UNPRICED ? formOverrides : {}}
            />
        );
    }

    return null;
}
