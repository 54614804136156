import React, { useState, Fragment, useEffect } from 'react';
import config from '../../utils/config';
import { useAuth } from '../../portal/context/auth-context';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';

export default function AddNewShippingMethod({
    open,
    setOpen,
    handleNewAddress,
    errorAddingAddress,
    setErrorAddingAddress,
}) {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();

    const [methodSelected, setMethodSelected] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [newAddressData, setNewAddressData] = useState({
        shipMethodId: '',
        shipMethodDescription: '',
        accountNumber: '',
        accountName: currentUser.Account.Name,
        accountId: currentUser.Account.Id,
        shipCarrier: '',
        isCustom: false,
    });

    const postNewMethod = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        newAddressData.shipMethodDescription = newAddressData.shipMethodDescription.replace(/-/g, ' ');

        newAddressData.accountNumber = newAddressData.accountNumber.replace(/-/g, ' ');

        let dataToSend = newAddressData;
        if (newAddressData.shipMethodId === 'custom') {
            dataToSend = {
                isCustom: true,
                shipMethodId: '',
                shipMethodDescription: newAddressData.shipMethodDescription,
                accountNumber: newAddressData.accountNumber,
                accountName: currentUser.Account.Name,
                accountId: currentUser.Account.Id,
                shipCarrier: '',
            };
        }

        const response = await fetch(`${ORIGIN}/cp/shipping/new-method`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });
        const data = await response.json();
        setIsLoading(false);
        setOpen(false);
        if (data.result === 'success') {
            newAddressData.shipMethodId = data.shipAddressId;
            handleNewAddress(newAddressData);
            setErrorAddingAddress(false);
        } else {
            setErrorAddingAddress(true);
        }
    };

    useEffect(() => {
        if (methodSelected) {
            currentUser.availableShipMethods.map((method) => {
                if (method.shipMethodId === methodSelected) {
                    setNewAddressData({
                        ...newAddressData,
                        shipMethodId: method.shipMethodId,
                        shipMethodName: method.shipMethodName,
                        shipMethodDescription: method.shipMethodName,
                        shipCarrier: '',
                    });
                }
            });
        }
    }, [methodSelected]);

    if (!currentUser.availableShipMethods) return null;
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative max-w-lg px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:p-6'>
                                <div>
                                    <div className='mt-3 text-center sm:mt-5'>
                                        <div className='relative'>
                                            <button
                                                className='absolute flex items-center justify-center w-6 h-6 mt-1 mr-2 text-white rounded-full cursor-pointer bg-blue-primary hover:bg-gray-400 right-1 -top-2'
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <XMarkIcon className='w-5 h-5' />
                                            </button>
                                        </div>
                                        <div className='px-2 py-5 mt-5 md:mt-0'>
                                            <h1 className='text-2xl font-bold text-gray-700'>Add New Shipping Method</h1>

                                            <form>
                                                <div className='p-2 overflow-hidden'>
                                                    <div className='px-4 py-5 bg-white sm:p-6'>
                                                        <div className='grid grid-cols-6 gap-6'>
                                                            {!methodSelected && (
                                                                <div className='col-span-6'>
                                                                    <label
                                                                        htmlFor='first-name'
                                                                        className='block text-sm font-medium text-left text-gray-700'
                                                                    >
                                                                        Shipping Method
                                                                    </label>

                                                                    <select
                                                                        onChange={(e) => {
                                                                            setMethodSelected(e.target.value);
                                                                            setNewAddressData({
                                                                                ...newAddressData,
                                                                                shipMethodId: e.target.value,
                                                                            });
                                                                        }}
                                                                        id='method'
                                                                        className='block w-full px-3 py-2 mt-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm '
                                                                    >
                                                                        <option value=''>Select Shipping Method</option>
                                                                        <option value='custom'>
                                                                            Create - (Will Call/Courier/Other)
                                                                        </option>
                                                                        {currentUser.availableShipMethods.map((method) => (
                                                                            <option
                                                                                key={method.shipMethodId}
                                                                                value={method.shipMethodId}
                                                                            >
                                                                                {method.shipMethodName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}

                                                            {methodSelected && (
                                                                <>
                                                                    <div className='col-span-6'>
                                                                        <label
                                                                            htmlFor='first-name'
                                                                            className='block text-sm font-medium text-left text-gray-700'
                                                                        >
                                                                            Name
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            name='shipAddressName'
                                                                            id='shipAddressName'
                                                                            value={
                                                                                newAddressData.shipMethodName ||
                                                                                'Create - (Will Call/Courier/Other)'
                                                                            }
                                                                            disabled={true}
                                                                            className='block w-full mt-1 bg-gray-100 border-gray-300 rounded-md shadow-sm opacity-75 sm:text-sm'
                                                                        />
                                                                    </div>
                                                                    <div className='col-span-6'>
                                                                        <label
                                                                            htmlFor='shipMethodDescription'
                                                                            className='block text-sm font-medium text-left text-gray-700'
                                                                        >
                                                                            Your Description
                                                                        </label>
                                                                        <input
                                                                            onChange={(e) => {
                                                                                setNewAddressData({
                                                                                    ...newAddressData,
                                                                                    shipMethodDescription: e.target.value,
                                                                                });
                                                                            }}
                                                                            type='text'
                                                                            name='shipMethodDescription'
                                                                            id='shipMethodDescription'
                                                                            value={newAddressData.shipMethodDescription}
                                                                            placeholder=''
                                                                            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                                                        />
                                                                    </div>
                                                                    <div className='col-span-6'>
                                                                        <label
                                                                            htmlFor='first-name'
                                                                            className='block text-sm font-medium text-left text-gray-700'
                                                                        >
                                                                            Account Number
                                                                        </label>
                                                                        <input
                                                                            onChange={(e) => {
                                                                                setNewAddressData({
                                                                                    ...newAddressData,
                                                                                    accountNumber: e.target.value,
                                                                                });
                                                                            }}
                                                                            type='text'
                                                                            name='accountNumber'
                                                                            id='accountNumber'
                                                                            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='px-4 py-3 text-right sm:px-6'>
                                                        <button
                                                            disabled={
                                                                newAddressData.accountNumber === '' ||
                                                                newAddressData.shipMethodDescription === '' ||
                                                                isLoading
                                                            }
                                                            onClick={postNewMethod}
                                                            type='button'
                                                            className='inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-blue-primary hover:bg-blue-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary'
                                                        >
                                                            {isLoading ? (
                                                                <>
                                                                    <svg
                                                                        role='status'
                                                                        className='inline w-4 h-4 mr-3 text-white animate-spin'
                                                                        viewBox='0 0 100 101'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                    >
                                                                        <path
                                                                            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                                                            fill='#E5E7EB'
                                                                        />
                                                                        <path
                                                                            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                                                            fill='currentColor'
                                                                        />
                                                                    </svg>
                                                                    loading...
                                                                </>
                                                            ) : (
                                                                'Add Method'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
