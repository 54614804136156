export const pageNames = {
    RELEASE_LINES: 'Release Lines',
    FORMS: 'Forms',
    PHYSICAL: 'Physical',
    PHYSICAL_CAMERA: 'Physical Camera',
    TRACE: 'Trace',
};

export const releaseLineStatuses = {
    PICKED: 'Picked',
    PENDING_SALES: 'Pending Sales',
    IN_QC: 'In QC',
};
